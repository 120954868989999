import { useEffect, useState } from 'react';
import styles from './ChangeTrackInput.module.scss';

const ChangeTrackInput = (
        { trackChanges, entity, setEntity, field, disabled, ...props }:
        { trackChanges: any, entity: any, setEntity: any, field: any, disabled?: any }) => {
    const [originalField, setOriginalField] = useState<boolean>();

    const handleChange = (newVal: any) => {
        console.log('handleChange->newVal', newVal);
        let newObj = [];
        newObj[field] = newVal;
        setEntity({ ...entity, ...newObj });
    }

    useEffect(() => {
        entity && originalField === undefined && setOriginalField(entity[field]);
    }, [entity, field, setOriginalField, originalField]);


    return (
        <>{entity && <div className={`flex-container ${styles.cti}`} {...props}>
            {trackChanges &&  <div className="flex-cell-fill"><input type="checkbox" disabled={true} defaultChecked={originalField ?? undefined} /></div>}
            <div className="flex-cell-fill"><input style={trackChanges ? {marginLeft: "2.4em"} : undefined} type="checkbox" disabled={disabled} defaultChecked={entity[field]} onChange={(e) => {
                console.log('onChange', e);
                handleChange(!e.target.checked);
                }} /></div>
        </div>}</>
        );
}

export default ChangeTrackInput;